import Button from '@/components/button';
import routes from '@/lib/routes';
import GoogleIcon from '@/components/icons/google-icon';

export default function() {
    return (
        <div className={'border-b border-muted-foreground/40 py-4'}>
            <Button href={routes.socialLogin('google')} isDark={false} className={"flex w-full"} >
                <span className={"w-4 h-4"}> <GoogleIcon /></span>  Login with Google
            </Button>
        </div>
    );
}
